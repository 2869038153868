<template>
    <b-modal
      id="item-other-expense-props-modal"
      size="xl"
      hide-header
      hide-footer
      centered
      @hidden="onHidden"
    >
        <div class="header d-flex flex-row justify-content-between">
            <p v-if="!form?.id" class="title">Novo Item</p>
            <p v-else class="title">Editar Item</p>
            <Close class="close" @click="close" />
        </div>
        <div class="content">
            <b-row>
                <b-col cols="12">
                    <b-form-group>
                        <label>Item</label>
                        <ItemSelect 
                            v-model="form.item"
                            :types="[ITEM.ITEM_TYPE_OTHER_EXPENSES]"
                            placeholder="Digite um identificador, código ou nome para buscar itens"
                            :disabled="!editable"
                        />
                        <b-form-invalid-feedback :state="!validated ? null : !!form.item">Campo obrigatório</b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
            </b-row>
            
            <hr/>
            <div class="module-title">Dados do faturamento</div>
            <b-row>
                <b-col cols="12">
                    <b-form-group>
                        <label>Vínculo Mat/Med <span class="help"> (Opcional)</span></label>
                        <MatMedSelector 
                            v-model="form.material_medicine"
                            @select="onSelectMatMed"
                            :disabled="!editable"
                        />
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group>
                        <label>
                            Código alternativo 
                            <span class="help"> (Opcional)</span>
                            <span v-b-tooltip.hover title="Utilize esse campo para enviar ao convênio um código diferente do padrão"><Info /></span>
                        </label>
                        <b-form-input 
                            id="alias_code"
                            autocomplete="off"
                            v-model="form.alias_code"
                            :disabled="!editable"
                        />
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group>
                        <label>
                            Nome alternativo 
                            <span class="help"> (Opcional)</span>
                            <span v-b-tooltip.hover title="Utilize esse campo para enviar ao convênio um nome diferente do padrão"><Info /></span>
                        </label>
                        <b-form-input 
                            id="alias_name"
                            autocomplete="off"
                            v-model="form.alias_name"
                            :disabled="!editable"
                        />
                    </b-form-group>
                </b-col>
                <b-col cols="8">
                    <b-form-group>
                        <label>Tabela</label>
                        <multiselect
                            id="table_number"
                            label="label"
                            track-by="value"
                            v-model="form.table_number"
                            :options="tableOptions"
                            :showLabels="false"
                            :searchable="false"
                            :allowEmpty="false"
                            placeholder="Selecionar"
                            class="with-border"
                            :disabled="!editable"
                        >
                            <template slot="caret">
                                <div class="chevron">
                                    <ChevronDown />
                                </div>
                            </template>
                            <template slot="noOptions"> Nenhuma opção </template>
                            <template slot="noResult"> Nenhum resultado </template>
                        </multiselect>
                        <b-form-invalid-feedback :state="isValidProp('table_number')">Campo obrigatório</b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
                <b-col cols="4">
                    <b-form-group>
                        <label>Código de despesa (CD)</label>
                        <multiselect
                            id="cd"
                            v-model="form.cd"
                            :options="cdOptions"
                            :showLabels="false"
                            :searchable="false"
                            :allowEmpty="false"
                            placeholder="Selecionar"
                            class="with-border"
                            :disabled="!editable"
                        >
                            <template slot="caret">
                                <div class="chevron">
                                    <ChevronDown />
                                </div>
                            </template>
                            <template slot="noOptions"> Nenhuma opção </template>
                            <template slot="noResult"> Nenhum resultado </template>
                        </multiselect>
                        <b-form-invalid-feedback :state="isValidProp('cd')">Campo obrigatório</b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group>
                        <label>Unidade de medida</label>
                        <UnitMeasurementSelect 
                            v-model="form.unit_measurement" 
                            :disabled="!editable"
                        />
                        <b-form-invalid-feedback :state="isValidProp('unit_measurement')">Campo obrigatório</b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
                <b-col cols="4">
                    <b-form-group>
                        <label for="unitary_value">
                            Valor Unitário
                        </label>
                        <money 
                            id="unitary_value"
                            v-model="form.unitary_value" 
                            class="form-control" 
                            :state="isValidProp('unitary_value')" 
                            :disabled="!editable"
                        />
                        <b-form-invalid-feedback :state="isValidProp('unitary_value')">Campo obrigatório</b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
                <b-col cols="2">
                    <b-form-group>
                        <label>Red/Acresc</label>
                        <b-form-input
                            type="number"
                            step="0.01"
                            min="0"
                            placeholder="0,00"
                            autocomplete="off"
                            v-model="form.decrease_or_increase_factor"
                            :state="isValidProp('decrease_or_increase_factor')" 
                            :disabled="!editable"
                        />
                        <b-form-invalid-feedback :state="isValidProp('decrease_or_increase_factor')">Campo obrigatório</b-form-invalid-feedback>
                    </b-form-group>
                </b-col>

                <b-col cols="4">
                    <b-form-group>
                        <label>Registro ANVISA<span class="help"> (Opcional)</span></label>
                        <b-form-input
                            placeholder="Descrever"
                            autocomplete="off"
                            v-model="form.anvisa_registration"
                            :disabled="!editable"
                        />
                    </b-form-group>
                </b-col>
                <b-col cols="4">
                    <b-form-group>
                        <label>Ref. do material no fabricante<span class="help"> (Opcional)</span></label>
                        <b-form-input
                            placeholder="Descrever"
                            autocomplete="off"
                            v-model="form.manufacturer_reference"
                            :disabled="!editable"
                        />
                    </b-form-group>
                </b-col>
                <b-col cols="4">
                    <b-form-group>
                        <label>Nº Autorização de Funcionamento<span class="help"> (Opcional)</span></label>
                        <b-form-input
                            placeholder="Descrever"
                            autocomplete="off"
                            v-model="form.authorization_number"
                            :disabled="!editable"
                        />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group>
                        <label>Descrição<span class="help"> (Opcional)</span></label>
                        <b-form-textarea
                            placeholder="Descrever"
                            rows="2"
                            max-rows="5"
                            autocomplete="off"
                            v-model="form.description"
                            :disabled="!editable"
                        />
                    </b-form-group>
                </b-col>
                
            </b-row>
            
            <b-row v-if="editable">
                <b-col>
                    <b-button 
                        variant="primary" 
                        class="float-right" 
                        :loading="loading"
                        :disabled="loading"
                        @click="save"
                    >
                        Salvar
                    </b-button>
                </b-col>
            </b-row>
        </div>
    </b-modal>
</template>
<script>
import api from '@itemOtherExpenseProps/api'
import * as ITEM from '@items/utils/constants'
import { TISS_TABLE_OPTIONS } from '@itemOtherExpenseProps/utils/constants'
import * as CONTANTS from '@itemOtherExpenseProps/utils/constants'
import { isValidForm, getPayload } from '@itemOtherExpenseProps/utils/validations'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
    components: {
        Close: () => import('@/assets/icons/close.svg'),
        Info: () => import('@/assets/icons/info.svg'),
        ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
        ItemSelect: () => import('@items/components/ItemSelect'),
        MatMedSelector: () => import('@matMed/components/MatMedSelector'),
        UnitMeasurementSelect: () => import('@itemOtherExpenseProps/components/UnitMeasurementSelect'),
    },
    props: {
        editable: {
            type: Boolean,
            default: true
        },
        groupType: String,
        tableId: {
            type: String,
            required: true
        },
        item: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            ITEM,
            form: { monocular: false },
            loading: false,
            validated: false,
            clinic: getCurrentClinic(),
            tableOptions: TISS_TABLE_OPTIONS,
            cdOptions: CONTANTS.EXPENSE_CD_TYPES
        }
    },
    methods: {
        close() {
            this.$bvModal.hide('item-other-expense-props-modal')
        },
        onHidden() {
            this.form = {}
            this.$emit('hidden')
        },
        isValidProp(prop) {
            if (!this.validated) return null
            if (prop === 'quantity' && this.form.quantity < 0) return false
            return !!this.form[prop]
        },
        async save() {
            this.validated = true 
            if (!isValidForm(this.form)) return
            const payload = {
                ...getPayload(this.form),
                table_id: this.tableId,
                clinic_id: this.clinic.id,
            }
            this.loading = true
            try {
                const { data } = !this.form.id ? await api.createItemOtherExpenseProp(payload) : await api.updateItemOtherExpenseProp(this.form.id, payload)
                this.$toast.success(`Item salvo com sucesso!`)
                this.$emit('saved', data)
                this.close()
            } catch (error) {
                this.$toast.error(error.message)
            } finally {
                this.validated = false
                this.loading = false
            }
        },
        changeHealthPlanProps(healthPlanProps) {
            this.form = {
                ...this.form,
                health_plan_props: { ...healthPlanProps, table_id: this.tableId }
            }
        },
        onSelectMatMed(materialMedicine) { 
            if (!materialMedicine) return        
            if (materialMedicine.type === 'Simpro') {
                this.form = {
                    ...this.form,
                    alias_code: materialMedicine.simpro.cd_tuss,
                    alias_name: materialMedicine.simpro.descricao,
                    anvisa_registration: materialMedicine.simpro.registro_anvisa,
                }
            } else if (materialMedicine.type === 'Brasindice') {
                this.form = {
                    ...this.form,
                    alias_code: materialMedicine.brasindice.cod_tuss,
                    alias_name: materialMedicine.brasindice.item,
                }
            }
        }
    },
    watch: {
        item(data) {
            if (!data) {
                this.form = {}
                return
            }
            this.form = {
                ...data,
                table_number: this.tableOptions.find(({ value }) => value === data?.table_number) 
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .content {
        padding: 1rem;
    }
    .module-title {
        font-size: 16px;
        font-weight: 700;
        color: var(--blue-500);
        margin-bottom: 1rem;
    }
    .radio-group {
        margin-top: 7px;
    }
</style>
<style lang="scss">
#item-other-expense-props-modal {
    .modal-body {
        padding: 0 !important;
        .header {
            padding: 24px;
            border-bottom: 1px solid var(--neutral-200);
            .title {
                font-weight: 600;
                font-size: 18px;
                color: var(--type-active);
                margin: 0;
            }
            .close {
                width: 24px;
                height: 24px;
                fill: black;
                cursor: pointer;
            }
        }
    }
}
</style>