export const isValidForm = form => {
    return form.item &&
        form.table_number &&
        form.cd &&
        form.unit_measurement &&
        form.decrease_or_increase_factor &&
        form.unitary_value >= 0
}

export const getPayload = form => {
    const payload = {
        ...form,
        item_id: form.item.id,
        table_number: form.table_number?.value || null,
        material_medicine_id: form.material_medicine?.id || null,
    }
    delete payload.item
    delete payload.material_medicine
    return payload
}